import { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import { SizeTypes } from '@/types/size'
import {
    TOP_MEDIA_MAX_HEIGHT,
    TOP_MEDIA_MAX_HEIGHT_MOBILE,
    TOP_MEDIA_MAX_WIDTH,
    TOP_MEDIA_MAX_WIDTH_MOBILE,
} from '@/utils/content/constants'
import { getValueByBreakpoint } from '@/helpers/breakpoint'
import { BreakPointDisplaySizes, sizesToBoolean } from '@/helpers/pageHelper'

export const useTopMediaDimensions = () => {
    // This is neccessary because for some reason, after being rendered server side it does not update the values correctly in dom when no rerendering is done, therefore wrong images are shown
    const [render, setRender] = useState(false)
    const theme = useTheme()
    const { isMobile } = useMemo(
        () =>
            sizesToBoolean(
                getValueByBreakpoint(
                    BreakPointDisplaySizes,
                    theme as { breakpoints: string[] },
                    SizeTypes.MOBILE
                )
            ),
        [theme]
    )

    useEffect(() => {
        setRender(true)
    }, [])

    if (!render) {
        return {
            width: TOP_MEDIA_MAX_WIDTH_MOBILE,
            height: TOP_MEDIA_MAX_HEIGHT_MOBILE,
        }
    }

    return {
        width: isMobile ? TOP_MEDIA_MAX_WIDTH_MOBILE : TOP_MEDIA_MAX_WIDTH,
        height: isMobile ? TOP_MEDIA_MAX_HEIGHT_MOBILE : TOP_MEDIA_MAX_HEIGHT,
    }
}
