import { useMemo, useState } from 'react'
import {
    prepareVideoPlaylist,
    PrepareVideoPlaylist,
} from '@/helpers/videoHelper/prepareVideoPlaylist'

const useVideoPlaylist = ({
    video,
    recommendedVideos,
    titleWithDuration,
}: PrepareVideoPlaylist) => {
    const playlist = useMemo(
        () => prepareVideoPlaylist({ video, recommendedVideos, titleWithDuration }),
        [video, recommendedVideos, titleWithDuration]
    )
    const [runningVideo, setRunningVideo] = useState(playlist[0])

    return {
        playlist,
        runningVideo,
        setRunningVideo,
    }
}

export default useVideoPlaylist
