import { PrepareVideoPlaylist, VideoPlaylistItem } from './types'
import { prepareVideoSources } from './utils'
import { takeLastTagWithKeys } from '@/helpers/tagsHelper'
import { mapTrackingData } from '@/utils/tracking/TrackingProvider'

export const prepareVideoPlaylist = ({
    video,
    recommendedVideos,
    titleWithDuration = false,
}: PrepareVideoPlaylist): VideoPlaylistItem[] => {
    return [
        {
            file: video.url,
            metaid: video.id as string,
            seoTitle: video?.seoTitle,
            title:
                titleWithDuration && video.duration
                    ? `${video.duration} | ${video.title}`
                    : video.title,
            agfTitle: video.title,
            sources: prepareVideoSources(video?.transformations),
            itemDescription: video.description,
            id: video.id,
            publishedAt: video.publishedAt,
            duration: video.duration,
            videoTag: takeLastTagWithKeys(video?.tags, ['iconUrl', 'title']),
            tracking: mapTrackingData(video.meta?.tracking),
            agfMatch: video.agfMatch,
            meta: video.meta,
            image: video.imageUrl?.replace(':widthx:height', '640x360'),
            imageUrl: video.imageUrl,
        },
        ...(recommendedVideos ?? []).map(item => ({
            file: item.url,
            metaid: item.id,
            title:
                titleWithDuration && item.duration
                    ? `${item.duration} | ${item.title}`
                    : item.title,
            agfTitle: item.title,
            seoTitle: item?.seoTitle ?? item.title,
            sources: prepareVideoSources(item.transformations),
            itemDescription: item.description,
            id: item.id,
            publishedAt: item.publishedAt,
            duration: item.duration,
            videoTag: takeLastTagWithKeys(item?.tags, ['iconUrl', 'title']),
            tracking: mapTrackingData(item.meta?.tracking),
            meta: item.meta,
            image: item.imageUrl?.replace(':widthx:height', '640x360'),
            agfMatch: item?.agfMatch ?? {},
            imageUrl: video.imageUrl,
        })),
    ]
}
